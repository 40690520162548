<template>
    <nav class="navigation-bar-simple">
        <NuxtLink
            class="navigation-bar-simple__logo"
            to="/"
        >
            <img
                src="~/assets/img/logo.svg"
                alt="Logo"
                loading="eager"
                fetchpriority="high"
                width="70"
                height="44"
            >
        </NuxtLink>

        <BaseButton
            :element="NuxtLink"
            to="/"
            class="base-button--secondary"
            size="small"
        >
            Terug naar home
        </BaseButton>
    </nav>
</template>


<script setup>
const NuxtLink = resolveComponent('NuxtLink');
</script>


<style lang="less" src="./TheNavBarSimple.less"></style>
