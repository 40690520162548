<template>
    <div class="layout layout--login">
        <div class="layout__background-svg">
            <svg
                width="199"
                height="397"
                viewBox="0 0 199 397"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M198.647 288.145L-297.736 396.801L-139.224 -195.589L198.687 288.112"
                    fill="#21B0E6"
                    fill-opacity="0.2"
                />
            </svg>
            <svg
                width="291"
                height="589"
                viewBox="0 0 291 589"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.0336799 237.687L367.384 588.765L540.269 0.40943L0.016084 237.637"
                    fill="#21B0E6"
                    fill-opacity="0.2"
                />
            </svg>
            <svg
                width="259"
                height="118"
                viewBox="0 0 259 118"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M221.346 0.845572L0.421413 127.329L258.427 294.117L221.372 0.844101"
                    fill="#21B0E6"
                    fill-opacity="0.2"
                />
            </svg>
        </div>

        <TheNavBarSimple />

        <div class="layout__content">
            <main>
                <slot />
            </main>
        </div>
    </div>
</template>


<script setup>
import TheNavBarSimple from '~/components/TheNavBarSimple/TheNavBarSimple.vue';

useHead({
    htmlAttrs: {
        lang: 'nl'
    },
    link: [
        {
            rel: 'icon',
            type: 'image/x-icon',
            href: '/favicons/tln.ico'
        }
    ]
});
</script>


<style lang="less">
.layout--login {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100dvh; // Fix for iOS/Safari, use `100vh` as fallback
    background: var(--color-darkblue);
    color: var(--color-lightest);
}

.layout--login .layout__content {
    .page-container();
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.layout--login .layout__background-svg {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;

    svg {
        position: absolute;
        overflow: visible;
        width: 25vmin;
        height: 50vmin;
    }

    svg:nth-child(1) {
        top: 15vmin;
        left: 0;

        @media @q-lg-max {
            top: 25vmin;
            width: 25vmin;
            height: 25vmin;
        }
    }

    svg:nth-child(2) {
        top: 25vmin;
        right: 0;

        @media @q-lg-max {
            top: auto;
            bottom: -10vmin;
            right: -10vmin;
            width: 50vmin;
            height: 50vmin;
        }
    }

    svg:nth-child(3) {
        left: 25vmin;
        bottom: -20vmin;
        width: 50vmin;

        @media @q-lg-max {
            left: -35vmin;
            bottom: 0;
            width: 50vmin;
            height: 50vmin;
        }
    }
}

.layout--login main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: calc(var(--navbar-simple-height) * 0.75);
}
</style>
